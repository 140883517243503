html,
body {
    padding: 5vh 5vw;
    margin: 0;
}

p,
li,
footer {
    font-style: italic;
}

h1,
h2,
h3 {
    color: #555;
    font-family: "Alfa Slab One", serif;
}

body {
    font-family: "Barlow Condensed", sans-serif;
    font-size: 3vh;
}

a {
    color: #07c;
}

a:visited {
    color: #c7c;
}

@media print {
    body {
        padding: 0;
    }
}

@media only screen and (max-width: 600px) {
    body {
        padding: 3vh 3vw;
    }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
    body {
        padding: 4vh 4vw;
    }
}

* {
    box-sizing: border-box;
}
